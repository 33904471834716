// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pBI-button {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

ion-button {
  --border-radius: 8px!important;
}

.embedding-powerBI {
  position: fixed;
  top: 140px;
  bottom: 5px;
}

ion-modal {
  --width: 300px;
  --height: 400px;
  --border-radius: 8px;
  left: 80%;
}

ion-modal ion-datetime {
  height: 382px;
}

ion-padding {
  background-color: var(--ion-color-secondary);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/embedding-report/embedding-report.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,uBAAA;AACF;;AACA;EACE,8BAAA;AAEF;;AACA;EACE,eAAA;EACA,UAAA;EACA,WAAA;AAEF;;AACA;EACE,cAAA;EACA,eAAA;EACA,oBAAA;EACA,SAAA;AAEF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,4CAAA;AACF","sourcesContent":[".pBI-button {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n}\nion-button {\n  --border-radius: 8px!important;\n}\n\n.embedding-powerBI {\n  position: fixed;\n  top: 140px;\n  bottom: 5px;\n}\n\nion-modal {\n  --width: 300px;\n  --height: 400px;\n  --border-radius: 8px;\n  left: 80%;\n  // bottom: 40%;\n}\n\nion-modal ion-datetime {\n  height: 382px;\n}\n\nion-padding {\n  background-color: var(--ion-color-secondary);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
