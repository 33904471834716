// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `hr {
  border-bottom: 1px solid var(--ion-color-primary);
}

.card-content {
  padding: 7px;
  padding-bottom: 0px;
  line-height: 1;
}

.helpPage-image {
  object-fit: scale-down;
  margin-left: auto;
  margin-right: auto;
  max-height: 100px;
}

.text {
  color: var(--ion-color-secondary-contrast);
  text-align: center;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.pinned-icon {
  margin-left: 10px;
  margin-top: 5px;
  color: var(--ion-color-secondary-contrast);
  zoom: 2;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/home/pages/user-help-page/user-help-page.component.scss"],"names":[],"mappings":"AAAA;EACE,iDAAA;AACF;;AACA;EACE,YAAA;EACA,mBAAA;EACA,cAAA;AAEF;;AAAA;EACE,sBAAA;EACA,iBAAA;EACA,kBAAA;EACA,iBAAA;AAGF;;AADA;EACE,0CAAA;EACA,kBAAA;EACA,gBAAA;EACA,oBAAA;EACA,qBAAA,EAAA,4BAAA;EACQ,aAAA;EACR,4BAAA;AAIF;;AADA;EACE,iBAAA;EACA,eAAA;EACA,0CAAA;EACA,OAAA;AAIF","sourcesContent":["hr {\n  border-bottom: 1px solid var(--ion-color-primary);\n}\n.card-content {\n  padding: 7px;\n  padding-bottom: 0px;\n  line-height: 1;\n}\n.helpPage-image {\n  object-fit: scale-down;\n  margin-left: auto;\n  margin-right: auto;\n  max-height: 100px;\n}\n.text {\n  color: var(--ion-color-secondary-contrast);\n  text-align: center;\n  overflow: hidden;\n  display: -webkit-box;\n  -webkit-line-clamp: 2; /* number of lines to show */\n          line-clamp: 2;\n  -webkit-box-orient: vertical;\n}\n\n.pinned-icon {\n  margin-left: 10px;\n  margin-top: 5px;\n  color: var(--ion-color-secondary-contrast);\n  zoom: 2\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
