import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { filter, tap, throwError } from 'rxjs';
import { ResourcesEndpointEnum } from 'src/app/core/enums/resources-endpoint.enum';
import { WindowSizes } from 'src/app/core/enums/window-sizes.enum';
import { GroupInterface } from 'src/app/core/interfaces/group.interface';
import { ReportConfig } from 'src/app/core/interfaces/report-config';
import { UserInterface } from 'src/app/core/interfaces/user.interface';
import { ResourceService } from 'src/app/core/services/resource.service';
import { NgIf, NgFor } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReportCardComponent } from '../../../../shared/components/report-card/report-card.component';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        IonicModule,
        NgFor,
        ReportCardComponent,
    ],
})
export class DashboardComponent implements OnInit {

  user: UserInterface;
  groups: GroupInterface[] = [];
  showLoadingReports = false;
  reports: ReportConfig[];
  sortedReports: ReportConfig[];
  public windowwidth = innerWidth;
  onmobileView = false;
  windowSizes = WindowSizes;

  constructor(private route: ActivatedRoute,
              public resourceService: ResourceService,
              private toastrService: ToastrService,
              private router: Router) { }

  ngOnInit() {
    if (this.windowwidth < this.windowSizes.MaxMobileWidth) {
      this.onmobileView = true;
    }
    this.route.data.pipe(
      tap((data: Data) => {
        this.user = data.user;
        this.groups = this.user?.Groups;
      })
    ).subscribe();

    if(this.groups?.length !== 0 && this.groups !== null) {
      this.showLoadingReports = true;
      this.getPinnedFavoriteReports();
    }
  }

  getPinnedFavoriteReports() {
    this.resourceService.get<ReportConfig[]>(ResourcesEndpointEnum.GET_PINNED_FAVORITES_REPORTS)
    .pipe(
      filter((reports: ReportConfig[] |null ) => reports !== null))
    .subscribe({
      next: (reports: ReportConfig[] |null ) => {
        this.showLoadingReports = false;
        this.reports = reports;
        this.sortReports(this.reports)
      },
      error: (err) => {
        this.toastrService.error('Error when loading reports');
        this.showLoadingReports = false;
        throwError(() => err);
      },
      complete: () => {
        this.showLoadingReports = false;
      }
    });
  }

  private sortReports(reports: ReportConfig[]) {
    this.sortedReports = reports.sort((a: ReportConfig, b: ReportConfig) => {
      // Pinned reports are first
      if (!a.Pinned && b.Pinned) {
          return 1;
      }
      if (a.Pinned && !b.Pinned) {
          return -1;
      }
      // Favorited reports are sorted  alphabetically
      if (a.Title > b.Title) {
        return 1;
      }
      if (b.Title > a.Title) {
          return -1;
      }
      return 0;
  });
  }
}

