// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ion-content {
  --background: var(--ion-color-primary) ;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/reports/pages/report/report.component.scss"],"names":[],"mappings":"AACE;EACE,uCAAA;AAAJ","sourcesContent":[":host {\n  ion-content {\n    --background:  var(--ion-color-primary) ;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
