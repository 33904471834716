import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ReportInterface } from 'src/app/core/interfaces/powerbi-report.interface';
import { NgIf } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { EmbeddingReportComponent } from '../../../../shared/components/embedding-report/embedding-report.component';

@Component({
    selector: 'app-report-embed',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        IonicModule,
        EmbeddingReportComponent,
    ],
})
export class ReportComponent implements OnInit {

  reportId: any;
  reportType: any;
  report: ReportInterface;
  groupId: number;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getReportInfo();
    this.getGroupId();
  }

  private getReportInfo() {
      this.route.params
      .subscribe(
        (params: Params) => {
          this.reportId = params.reportId;
          // this.reportType = params.reportType;
          this.reportType = params.reportType === 'Dashboard'? 'Dashboard' : 'Report';
        }
      );
    }
    private getGroupId() {
      this.route.queryParams.subscribe((params: any) => {
        this.groupId = params.id
      })
    }

}
