import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private msalService: MsalService,
              private msalBroadcastService: MsalBroadcastService,
              private router: Router) { }

  setLoginDisplay(): boolean {
    return this.msalService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  public isLoggedIn() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });
    return this.msalService.instance.getActiveAccount();
  }

  public login() {
    this.msalService.initialize().subscribe((x) => {
      this.msalService.instance.loginRedirect();
      this.router.navigate(['/home']);
    })
  }

  public logout() {
    this.msalService.logoutRedirect();
  }

  handleAdminGuardRouting(): void {
    this.msalBroadcastService.msalSubject$.pipe(
      take(1), // TODO: Not needed?
      tap(()  => {
        if(this.setLoginDisplay()) {
          // const path = window.location.href.split('/')
          // console.log('window.location.href', window.location.href.split('/').pop())
          this.router.navigate(['home']);
        }
      })
    ).subscribe();
  }

}
