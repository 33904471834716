import { Component } from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { IonicModule } from '@ionic/angular';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    standalone: true,
    imports: [IonicModule, NgIf],
})
export class AppComponent {
  isIframe = false;


  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
  }
}
