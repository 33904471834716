import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ModalController, IonicModule } from '@ionic/angular';
import { UserInterface } from 'src/app/core/interfaces/user.interface';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-user-modal',
    templateUrl: './user-modal.component.html',
    styleUrls: ['./user-modal.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        ReactiveFormsModule,
        NgIf,
    ],
})
export class UserModalComponent implements OnInit {
  user?: UserInterface;
  userForm!: FormGroup;
  userFormProcessing = false;
  userFormSubmitted = false;

  /**
   *
   */
  constructor(public modalController: ModalController,
              private formBuilder: FormBuilder) { }

  /**
   *
   */
  ngOnInit(): void {
    this.buildForm();
  }

  /**
   *
   */
  saveUser(): void {
    this.userFormProcessing = true;
    this.userFormSubmitted = true;

    if (this.userForm.invalid) {
      this.userFormProcessing = false;
      return;
    }

    const userForm = this.userForm.value;

    this.modalController.dismiss({
      userForm,
      action: this.user ? 'edit' : 'create'
    }).catch();
  }

  /**
   *
   * @private
   */
  private buildForm(): void {
    this.userForm = this.formBuilder.group({
      FirstName: [this.user ? this.user.FirstName : null, [Validators.required]],
      LastName: [this.user ? this.user.LastName : null, [Validators.required]],
      Email: [this.user ? this.user.Email : null, [Validators.required]],
    });

  }

  onBack() {
    this.modalController.dismiss(false);
  }
}
