import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError } from 'rxjs';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
      const toastrService = inject(ToastrService);
      const router = inject(Router);
      const activatedRoute = inject(ActivatedRoute);
  return next(req).pipe(
      catchError((err) => {
        console.log('err in interceptor: ',err)
        let errorMessage = "";
        if (err.status === 401) {
          errorMessage = "Unauthozied. You have provided invalid credentials. Please contact IT.";
          router.navigate([ window.location.origin + '/login'], {relativeTo:  activatedRoute});
        }
        else if(err.status === 403) {
          errorMessage = "Forbidden. You do not have access to the resource";
        }
        else if(err.status === 500) {
          errorMessage = "Internal Server Error. Please contact IT.";
        }
        else if(err.status === 0){
          errorMessage = "Could not connect to server. Please contact IT.";
        }
        else if(err.status === 400){
          errorMessage = err.error;
        }
        return throwError(()  => toastrService.error(errorMessage));
      })
    );
};
