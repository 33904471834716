// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text {
  color: var(--ion-color-primary-contrast);
  font-size: 25px;
  text-align: center;
}

img {
  max-width: 70%;
}

.mobile-text {
  color: var(--ion-color-primary-contrast);
  font-size: 16px;
  text-align: center;
}

ion-card {
  width: 150px;
  height: 150px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/home/pages/dashboard/dashboard.component.scss"],"names":[],"mappings":"AAAA;EACE,wCAAA;EACA,eAAA;EACA,kBAAA;AACF;;AACA;EACE,cAAA;AAEF;;AAAA;EACE,wCAAA;EACA,eAAA;EACA,kBAAA;AAGF;;AAAA;EACE,YAAA;EACA,aAAA;AAGF","sourcesContent":[".text {\n  color: var(--ion-color-primary-contrast);\n  font-size: 25px;\n  text-align: center;\n}\nimg {\n  max-width: 70%;\n}\n.mobile-text {\n  color: var(--ion-color-primary-contrast);\n  font-size: 16px;\n  text-align: center;\n}\n\nion-card {\n  width: 150px;\n  height: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
