import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ModalController, IonicModule } from '@ionic/angular';
import { FormHelper } from 'src/app/core/helpers/form.helper';
import { ReportInterface } from 'src/app/core/interfaces/powerbi-report.interface';
import { ReportConfig } from 'src/app/core/interfaces/report-config';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-report-modal',
    templateUrl: './report-modal.component.html',
    styleUrls: ['./report-modal.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        ReactiveFormsModule,
        NgIf,
    ],
})
export class ReportModalComponent implements OnInit {
  @Input()
  report?: ReportConfig;

  reportForm!: FormGroup;
  reportFormProcessing = false;
  reportFormSubmitted = false;
  hasRLS = false;
  orderForm: FormGroup;
  formHelper = FormHelper;

  constructor(public modalController: ModalController,
              private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.hasRLS = this.report.RLS ? this.report.RLS : false;
    this.buildForm();
  }

  buildForm() {
    this.reportForm = this.formBuilder.group({
      ReportID: [this.report.ReportID],
      Title: [this.report ? this.report.Title : null, [Validators.required]],
      ReportGUID: [this.report ? this.report.ReportGUID : null, [Validators.required]],
      ReportType: this.formBuilder.group({
        ReportTypeID: [this.report.ReportType.ReportTypeID],
        ReportType: [this.report.ReportType.ReportType]
      }),
      Description: [this.report ? this.report.Description : null],
      MobileFriendly: [this.report ? this.report.MobileFriendly : null],
      RLS: [this.report ? (this.report.RLS ? this.report.RLS : false) : null],
      RLSRoleName: [this.report ? (this.report.RLSRoleName ? this.report.RLSRoleName : "") : null],
      RLSFilteredOnEmail: [this.report ? (this.report.RLSFilteredOnEmail ? this.report.RLSFilteredOnEmail : false) : false],
      Published: [this.report ? this.report.Published : null,  [Validators.required]],
      Pinned: [this.report ? this.report.Pinned : null]
    });
  }

  saveReport(): void {
    this.reportFormProcessing = true;
    this.reportFormSubmitted = true;
    if (this.reportForm.invalid) {
      this.reportFormProcessing = false;
      return;
    }

    const reportForm = this.reportForm.value;
    void this.modalController.dismiss({
      reportForm,
      action: this.report ? 'edit' : 'create'
    });
  }

  onBack() {
    this.modalController.dismiss(false);
  }

  enabledRLS(event: any) {
    if(event.detail.checked)
      this.hasRLS = true
    else
      this.hasRLS = false
  }
}
