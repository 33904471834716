// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  height: 744px !important;
}

ion-radio {
  --color-checked: var(--ion-color-success);
  --color:var(--ion-color-success);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/report-modal/report-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;AACF;;AACA;EACE,yCAAA;EACA,gCAAA;AAEF","sourcesContent":["ion-content {\n  height: 744px !important;\n}\nion-radio {\n  --color-checked: var(--ion-color-success);\n  --color:var(--ion-color-success);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
