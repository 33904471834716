import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ConfigurationPopoverComponent } from './components/configuration-popover/configuration-popover.component';
import { AddUserModalComponent } from './components/modals/add-user-modal/add-user-modal.component';
import { GroupModalComponent } from './components/modals/group-modal/group-modal.component';
import { ReportModalComponent } from './components/modals/report-modal/report-modal.component';
import { UserModalComponent } from './components/modals/user-modal/user-modal.component';
import { DashboardComponent } from '../modules/home/pages/dashboard/dashboard.component';
import { ReportCardComponent } from './components/report-card/report-card.component';
import { ReportComponent } from '../modules/reports/pages/report/report.component';
import { EmbeddingReportComponent } from './components/embedding-report/embedding-report.component';
import { QuillModule } from 'ngx-quill';
import { UserHelpPageComponent } from '../modules/home/pages/user-help-page/user-help-page.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
    exports: [
        IonicModule,
        NgxDatatableModule,
        ReactiveFormsModule,
        RouterModule,
        ReportCardComponent,
        EmbeddingReportComponent,
        ReportComponent,
        UserHelpPageComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        RouterModule,
        ReactiveFormsModule,
        QuillModule.forRoot(),
        UserModalComponent,
        GroupModalComponent,
        AddUserModalComponent,
        ReportModalComponent,
        ReportCardComponent,
        DashboardComponent,
        ReportComponent,
        EmbeddingReportComponent,
        ConfigurationPopoverComponent,
        UserHelpPageComponent
    ]
})
export class SharedModule {
}
