export enum ResourcesEndpointEnum {
  'GET_ROLE' = '/me',

  'GET_GROUPS' = '/groups',
  'GET_GROUPSREPORTCONFIG' = '/reportConfig/groups',
  'GET_GROUP' = '/groups/:groupID',
  'POST_GROUP' = '/groups',
  'PUT_GROUP' = '/groups',
  'DEACTIVATE_GROUP' = '/groups/:groupID',
  'ACTIVATE_GROUP' = '/groups',
  'UPLOAD_LOGO' = '/groups/:groupID/logo/:fileName',
  'GET_GROUP_REPORTS' = '/groups/:groupID/publishedReports',
  'GET_GROUP_REPORTS_DETAILS' = '/groups/:groupID/Reports',

  'GET_USERS' = '/users',
  'GET_USER' = '/users/:userID',
  'POST_USER' = '/users/:securityGroupID',//Invite User -post request
  'GIVE_USER_ACCESS' = '/users/:securityGroupID',//Select User - put request
  'PUT_USER' = '/users',
  'REVOKE_USER_ACCESS' = '/groups/:groupID/RevokeAccessUser/:userID',
  'DELETE_USER' = '/user/:userID',
  'GET_API_REPORT' = '/powerbi/:groupID/:reportType/:reportID',
  'GET_REPORT' = '/groups/:groupID/report/:reportID',
  'GET_PINNED_FAVORITES_REPORTS' = '/reports/pinned-favorites',
  'PUT_REPORT' = '/report/:reportID',
  'PUT_REPORT_FAVORITE' = '/report/:reportID/favorite',

  'GET_ADMIN_HELP_PAGES' = '/config/help-pages',
  'GET_HELP_PAGE' = '/help-pages/:helpPageID',
  'GET_HELP_PAGES' = '/help-pages',
  'PUT_HELP_PAGE' = '/config/help-pages/:helpPageID',
  'POST_HELP_PAGE' = '/config/help-pages',
  'DELETE_HELP_PAGE' = '/config/help-pages/:helpPageID',
  'UPLOAD_IMAGE' = '/blobUploader/help-pages-images/:fileName'
}
