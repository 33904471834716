import { Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as pbi from 'powerbi-client';
import { IDashboardEmbedConfiguration, IEmbedConfiguration } from 'powerbi-client';
import { ToastrService } from 'ngx-toastr';
import { WindowSizes } from 'src/app/core/enums/window-sizes.enum';
import { LayoutShowCaseState } from '../interfaces/layout-show-case-state';
import { ResourceService } from './resource.service';

@Injectable({
  providedIn: 'root',
})
export class PowerbiService {

  exportToPDFAndDownload(reportId: string) {
    throw new Error('Method not implemented.');
  }
  public embedder: pbi.Report | pbi.Dashboard;
  public config: IEmbedConfiguration;
  public configDashboard: IDashboardEmbedConfiguration;
  public models = pbi.models;
  public settings: any;
  pageView: any;
  windowSizes = WindowSizes;
  public windowwidth = window.innerWidth;
  LayoutShowcaseState: LayoutShowCaseState;
  ColumnsNumber = {
    One: 1,
    Two: 2,
    Three: 3,
  };

  LayoutShowcaseConsts = {
    margin: 15,
    minPageWidth: 270,
  };
  public powerbi = new pbi.service.Service(
    pbi.factories.hpmFactory,
    pbi.factories.wpmpFactory,
    pbi.factories.routerFactory
  );

  chosenReport: any;
  visuals: any;
  filterSignal = signal([''])

  constructor(private httpClient: HttpClient, private toastrService: ToastrService, public resourceService: ResourceService) {}

  public async retrieveEmbedObject(embedConfig: any) {
    this.settings = {
      // background: this.models.BackgroundType.Transparent,
      // visualRenderedEvents: true,
      panes: {
        filters: {
          visible: false
        },
        pageNavigation: {
          visible: false
        }
      }
    };
    // if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    //   console.log('dark mode')
    // } else {
    //   console.log('light mode');
    //   this.settings.background = this.models.BackgroundType.Transparent;
    // }
    const contentType: string = embedConfig.EmbedType !== 'Dashboard' ? 'report' : 'dashboard';
    if (contentType == 'dashboard') {
      this.setEmbedConfig(embedConfig, contentType);
      if (this.windowwidth < this.windowSizes.MaxMobileWidth) {
        this.pageView = 'oneColumn';
      } else {
        this.pageView = 'fitToWidth';
      }

      this.configDashboard = {
        type: contentType,
        tokenType: this.models.TokenType.Embed,
        accessToken: embedConfig.EmbedToken.Token,
        permissions: this.models.Permissions.All,
        embedUrl: embedConfig.EmbedUrl,
        id: embedConfig.Id,
        pageView: this.pageView
      };
      this.showDashboard();
    };
    if(contentType === 'report') {
      if (this.windowwidth < this.windowSizes.MaxMobileWidth) {
        this.settings.layoutType = this.models.LayoutType.MobilePortrait;
      } else {
        this.settings.layoutType = this.models.LayoutType.MobileLandscape;
        this.setEmbedDisplaySettings(this.models.DisplayOption.FitToPage);
      }
        this.setEmbedConfig(embedConfig, contentType);
        this.showReport();
    }
  }

  private setEmbedConfig(embedConfig: any, powerbiContentType: string): void {
      const embedConfiguration = {
        tokenType: this.models.TokenType.Embed,
        accessToken: embedConfig.EmbedToken.Token,
        permissions: this.models.Permissions.All,
        embedUrl: embedConfig.EmbedUrl,
        id: embedConfig.Id,
        settings: this.settings,
        type: powerbiContentType
      };
      this.config = embedConfiguration;
  }
  public setReportSize(displayOption: any) {
      this.setEmbedDisplaySettings(displayOption);
      const reportContainer = document.getElementById('reportContainer') as HTMLElement;
      this.chosenReport = this.powerbi.get(reportContainer);
      this.chosenReport.updateSettings(this.settings);
      // this.showReport();
  }

    private setEmbedDisplaySettings(displayOption: number) {
    this.LayoutShowcaseState = {} as LayoutShowCaseState;
    this.settings = {
      navContentPaneEnabled: false,
      filterPaneEnabled: false,
      layoutType: this.models.LayoutType.Custom,
      customLayout: {
        displayOption: displayOption
      },
    };
    if (this.windowwidth < this.windowSizes.MaxMobileWidth) {
      this.LayoutShowcaseState.columns = this.ColumnsNumber.One;
    }
  }

  public toggleEditMode(editMode: boolean) {
    if (this.embedder instanceof pbi.Report) {
      if (editMode) {
        this.embedder.switchMode('edit');
      } else {
        this.embedder.switchMode('view');
      }
    }
  }

  private downloadFile(url: string){
    return  this.httpClient.get(url, {reportProgress: true, responseType: 'blob'});
  }
  public async reload(){
    this.embedder.reload();
    await this.chosenReport.bookmarksManager.play(this.models.BookmarksPlayMode.Off);
  }

  async showDashboard(){
    const dashboardContainer = document.getElementById(
      'dashboardContainer'
      ) as HTMLElement;
    this.embedder = this.powerbi.embed(dashboardContainer, this.configDashboard) as pbi.Dashboard;
    this.chosenReport = this.powerbi.get(dashboardContainer);
    this.chosenReport.updateSettings(this.settings);
    this.powerbi.reset(this.embedder as any);
    this.fixIframes();
  }
  async showReport() {
    // Grab the reference to the div HTML element that will host the report.
    // Embed the report and display it within the div container.
    const reportContainer = document.getElementById(
      'reportContainer'
      ) as HTMLElement;

      this.embedder = this.powerbi.embed(reportContainer, this.config) as pbi.Report;
      this.chosenReport = this.powerbi.get(reportContainer) as pbi.Report;
      this.chosenReport.updateSettings(this.settings);
      this.powerbi.reset(this.embedder as any);
      this.fixIframes();
  }

  /**Helping method to remove the border around the iframe  */
  fixIframes() {
    try {
      if (window.document.getElementsByTagName('iframe')) {
        const iFrameElements = window.document.getElementsByTagName('iframe');
        for (let i = 0; i < iFrameElements.length; i++) {
          iFrameElements[i].style.border = '0'; //  For other browsers.
          iFrameElements[i].setAttribute('frameBorder', '0'); //  For other browsers (just a backup for the above).
          // iFrameElements[i].contentWindow.document.body.style.border? = 'none'; //  For IE.
        }
      }
    } catch (Exception) {
      console.log('Error fixing Iframes', Exception);
    }
  }
  public showFullscreen() {
    this.embedder.fullscreen();
  }
  public refresh() {
    if (this.embedder instanceof pbi.Report) {
      this.embedder.refresh();
    }
  }


  public print() {
    if (this.embedder instanceof pbi.Report) {
      this.embedder
        .print()
        .catch((error) => console.log('Error when printing: ', error));
    }
  }

}

